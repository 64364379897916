var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "breadcrumb-wrap" },
    [
      _c(
        "a-breadcrumb",
        { staticClass: "breadcrumb" },
        _vm._l(_vm.list, function (item) {
          return _c(
            "a-breadcrumb-item",
            { key: item.href },
            [
              _c("router-link", { attrs: { to: item.href } }, [
                _vm._v(" " + _vm._s(item.label) + " "),
              ]),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "text" },
        [
          _vm.back.isBack
            ? _c("a-icon", {
                staticClass: "back",
                attrs: { type: "arrow-left" },
                on: { click: _vm.toBack },
              })
            : _vm._e(),
          _c("span", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.title.title)),
          ]),
          _c("span", { staticClass: "desc" }, [_vm._v(_vm._s(_vm.title.desc))]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }