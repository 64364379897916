<template>
    <a-menu
        style="width: 252px; min-height: 90vh"
        :default-open-keys="openKeys"
        :selected-keys="defaultSelectedKeys"
        mode="inline"
        theme="light"
        @select="changeMenu">
        <template v-for="item in menuList">
            <a-menu-item v-if="item.children === false" :key="item.router">
                <a-icon :type="item.icon" />
                <span>{{ item.name }}</span>
            </a-menu-item>
            <a-sub-menu v-else :key="item.router">
                <span slot="title"><a-icon :type="item.icon" /><span>{{ item.name }}</span></span>
                <a-menu-item v-for="node in item.children" :key="node.router">
                    {{ node.name }}
                </a-menu-item>
            </a-sub-menu>
        </template>
    </a-menu>
</template>

<script>
import { list } from '@/config/menu'
export default {
    name: "Menu",
    data() {
        return {
            menuList: list,
            openKeys: [],
            defaultSelectedKeys: []
        }
    },
    watch: {
        $route() {
            this.setMenuByRoute()
        }
    },
    created() {
        this.setMenuByRoute()
    },
    methods: {
        setMenuByRoute() {
            let routeData = '/' + this.$route.path.split('/')[1]
            this.defaultSelectedKeys.length = 0
            this.defaultSelectedKeys.push(routeData)
            list.forEach((item) => {
                if(item.children !== false) {
                    item.children.forEach((node) => {
                        if(node.router === routeData) {
                            this.openKeys.length = 0
                            this.openKeys.push(item.router)
                        }
                    })
                }
            })
        },
        changeMenu(val) {
            if (!(this.$route.path === val.key)) {
                this.$router.push(val.key)
            }
        }
    }
}
</script>

<style scoped>

</style>
