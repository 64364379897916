var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c("Header"),
      _c(
        "div",
        { staticClass: "warpper" },
        [
          _c("Menu"),
          _c(
            "div",
            { staticStyle: { flex: "1" } },
            [
              _c("Breadcrumb"),
              _c(
                "keep-alive",
                [_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e()],
                1
              ),
              !_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }