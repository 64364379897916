var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-menu",
    {
      staticStyle: { width: "252px", "min-height": "90vh" },
      attrs: {
        "default-open-keys": _vm.openKeys,
        "selected-keys": _vm.defaultSelectedKeys,
        mode: "inline",
        theme: "light",
      },
      on: { select: _vm.changeMenu },
    },
    [
      _vm._l(_vm.menuList, function (item) {
        return [
          item.children === false
            ? _c(
                "a-menu-item",
                { key: item.router },
                [
                  _c("a-icon", { attrs: { type: item.icon } }),
                  _c("span", [_vm._v(_vm._s(item.name))]),
                ],
                1
              )
            : _c(
                "a-sub-menu",
                { key: item.router },
                [
                  _c(
                    "span",
                    { attrs: { slot: "title" }, slot: "title" },
                    [
                      _c("a-icon", { attrs: { type: item.icon } }),
                      _c("span", [_vm._v(_vm._s(item.name))]),
                    ],
                    1
                  ),
                  _vm._l(item.children, function (node) {
                    return _c("a-menu-item", { key: node.router }, [
                      _vm._v(" " + _vm._s(node.name) + " "),
                    ])
                  }),
                ],
                2
              ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }