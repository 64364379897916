<template>
  <div class="home">
    <Header />
    <div class="warpper">
      <Menu />
      <div style="flex: 1">
        <Breadcrumb />
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/Header";
import Menu from "@/views/Menu";
import Breadcrumb from "@/components/Breadcrumb";
export default {
  name: "Home",
  components: {
    Header,
    Menu,
    Breadcrumb,
  },
};
</script>

<style lang="scss" scoped>
.warpper {
  display: flex;
}
</style>
