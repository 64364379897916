<template>
  <div class="breadcrumb-wrap">
    <a-breadcrumb class="breadcrumb">
    <a-breadcrumb-item v-for="item in list" :key="item.href">
        <router-link :to="item.href">
            {{item.label}}
        </router-link>
    </a-breadcrumb-item>
  </a-breadcrumb>
  <div class="text">
      <!-- <router-link v-if="back.isBack" :to="back.path"> -->
        <a-icon v-if="back.isBack" type="arrow-left" class="back" @click="toBack" />
      <!-- </router-link> -->
      <span class="title">{{title.title}}</span>
      <span class="desc">{{title.desc}}</span>
  </div>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  data(){
      return {
          list:[],
          back:{
              isBack: false,
              path: '',
          },
          title:{
              title: '',
              desc: ''
          }
      }
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        // console.log("route", newVal, oldVal);
        // console.log(this.$router);
        const isBack = newVal.matched[newVal.matched.length-1].meta.isBack
        // const data = isBack?oldVal:newVal
        this.list = newVal.matched.map((item, index) => {
            if (index === 0) {
                return {
                    label: item.meta.label,
                    href: item.redirect
                }
            }
            return {
                label: item.meta.label,
                href: item.path
            }
        })
        const item = newVal.matched[newVal.matched.length-1]
        if (item.meta.parentRout) {
            this.list.splice(this.list.length-1, 0, {
                label: item.meta.parentRout.label,
                href: item.meta.parentRout.path
            })
        }
        this.back.isBack = isBack
        if (isBack) {
            this.back.path = item.meta.parentRout.path
        }
        this.title = {
            title: item.meta.title,
            desc:item.meta.desc
        }
      },
      immediate: true
    },
  },
  methods:{
      toBack(){
          this.$router.back(-1)
      }
  }
};
</script>

<style scoped lang='scss'>
    .breadcrumb-wrap{
        background: #fafafa;
        padding: 16px 24px;
        .breadcrumb{
            margin-bottom: 14px;
        }
        .text{
            display: flex;
            align-items: center;
            .back{
                color: rgba(0, 0, 0, 0.85);
                margin-right: 17px;
            }
            .title{
                font-size: 20px;
                color: rgba(0, 0, 0, 0.85);
                font-weight: bold;
                margin-right: 12px;
            }
            .desc{
                color: rgba(0, 0, 0, 0.45)
            }
        }
    }
</style>
