export const list = [
    {
        "name": '平台概览',
        "router": '/home',
        "icon": 'appstore',
        "children": false
    },
    {
        "name": '设备管理',
        "router": '/equip',
        "icon": 'desktop',
        "children": [
            {
                "name": '产品管理',
                "router": '/product-list'
            },
            {
                "name": '设备管理',
                "router": '/equip-list'
            },
            {
                "name": '设备分组',
                "router": '/equip-group'
            }
        ]
    },
    {
        "name": '设备接入',
        "router": '/access',
        "icon": 'api',
        "children": [
            {
                "name": '标准协议接入',
                "router": '/access-view'
            },
            {
                "name": '泛协议接入',
                "router": '/extensive-access'
            }
        ]
    },
    {
        "name": '运维监控',
        "router": '/control',
        "icon": 'dashboard',
        "children": [
            {
                "name": '设备日志',
                "router": '/equip-log'
            },
            {
                "name": '规则引擎',
                "router": '/warning-center'
            }
        ]
    },
    // {
    //     "name": '增值服务',
    //     "router": '/server',
    //     "icon": 'crown',
    //     "children": [
    //         {
    //             "name": 'API调试',
    //             "router": '/api-debug'
    //         },
    //         {
    //             "name": 'LBS位置服务',
    //             "router": '/lbs-place'
    //         }
    //     ]
    // },
    {
        "name": '视频接入',
        "router": '/video',
        "icon": 'play-square',
        "children": [
            {
                "name": '视屏监控',
                "router": '/video-monitoring'
            },
        ]
    }
]
